@tailwind base;
@tailwind components;
@tailwind utilities;

html * , body *  {
  scroll-behavior: smooth;
}
.range-slider {
  height: 5px;
  position: relative;
  background-color: #1b53c0;
  border-radius: 2px;
}
.range-selected {
  height: 100%;
  left: 30%;
  right: 30%;
  position: absolute;
  border-radius: 5px;
  background-color: #1b53c0;
}

.range-input {
  position: relative;
}
.range-input input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -7px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.range-input input::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #1b53c0;
  background-color: #fff;
  pointer-events: auto;
  -webkit-appearance: none;
}
.range-input input::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 3px solid #1b53c0;
  background-color: #fff;
  pointer-events: auto;
  -moz-appearance: none;
}
.range-price {
  margin: 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.range-price label {
  margin-right: 5px;
}
.range-price input {
  width: 40px;
  padding: 5px;
}
.range-price input:first-of-type {
  margin-right: 15px;
}